:root {
    --primary: #032158;
    --lightPrimary: rgba(3, 33, 88, 0.5);
    --hoverPrimary: #031436;
    --lightGray: #ced4da;
    --muted : #70657b; 
}

@import url(../fonts/iconsmind/iconsmind.css);

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
    width: device-width;
}

body {
    margin: 0;
    font-family: "Nunito", sans-serif;
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    background-color: #fff;
}

.bg-primary {
    background-color: var(--primary) !important;
}

.hover\:primary:hover {
    color: var(--primary) !important;
}

.hover\:bg-lightPrimary:hover {
    background-color: var(--hoverPrimary) !important;
}

.primary {
    color: var(--primary);
}

.fill-primary {
    fill: var(--primary);
}

.border-gray {
    border-color: var(--lightGray);
}

input[type='text']:focus,input[type='password']:focus,input[type='email']:focus,textarea:focus {
    border-color: var(--hoverPrimary);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 33, 88, 0.25);
}

select:focus {
    border-color: var(--hoverPrimary);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 33, 88, 0.25);
}

.text-muted{
    color: var(--muted);
}

.blur-background {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.blur-background:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    filter: blur(10px); /* Adjust blur intensity as needed */
    z-index: 9999;
    background-color: rgba(229, 231, 235, 0.3);
}

.loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.css-13cymwt-control {
    background-color: #ffffff !important;
    border-radius: 0.375rem !important;
    border-color: rgb(229, 231, 235) !important;
    min-height: 36px !important;
}

.css-qbdosj-Input > input {
    box-shadow: none !important;
}
.css-qbdosj-Input > input:focus {
    box-shadow: none !important;
}

.css-166bipr-Input > input:focus {
    box-shadow: none !important;
}
.css-1xc3v61-indicatorContainer {

    color: #000000 !important;

    padding: 0px !important;

}

.css-1u9des2-indicatorSeparator {

    width: 0px !important;

}

.css-1dimb5e-singleValue {

    margin-left: 6px !important;

    color: #000000 !important;

}

.react-datepicker__year-dropdown {

    max-height: 240px !important;

    overflow: scroll;

}

.css-1fdsijx-ValueContainer {

    padding-top: 0px !important;

    padding-bottom: 0px !important;

}


/*Pro Slider CSS*/
.css-1wvake5 {
    border-right-width: 0px !important;
    width: 100% !important;
    min-width: 100% !important;
}

span.ps-menu-label.css-12w9als {
    display: flex;
}

.ps-menu-button:hover {
    background-color: transparent !important;
}

.css-18unl23 {
    background-color: transparent !important;
}

.subMenu-bg {
    background-color: rgb(237 245 242 / 46%) !important
}

input[type="checkbox"]:disabled{
    background-color: #4c514c !important;
}