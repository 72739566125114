.NotLoggedInHomePageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*height: 100vh;*/
  height: calc(100vh - 100px);
  width: 0;
  flex-grow: 1;
}

.NotLoggedInHomePageContainer > div {
  align-self: center;
  width: 55%;
  display: flex;
  flex-direction: column;
}

.NotLoggedInHomePageContainer .LinksContainer {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.NotLoggedInHomePageContainer .LinksContainer span {
  font-style: italic;
  font-size: 0.9rem;
  text-align: right;
}

.NotLoggedInHomePageContainer .LinksContainer .NewMember {
  margin-bottom: 5px;
}

.footer_not_login {
  flex-grow: 1;
  padding: 15px;
  background-color: black;
}

.footer_not_login p{
  color: rgb(156 163 175/1);
  text-align: left;
  font-size: 0.9rem;
}

.text {
  font-size: 2rem;
}