.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip-icon {
    width: 16px; /* Adjust as necessary */
    height: 16px; /* Adjust as necessary */
}

.tooltip-text-container {
    display: none;
    position: absolute;
    left: calc(100% + 10px); /* Adjust for desired distance from icon */
    top: 50%;
    transform: translateY(-50%);
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    padding: 8px 12px; /* Adjust padding as necessary */
    font-size: 14px; /* Adjust font size as necessary */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1900;
    white-space: nowrap; /* Prevent text from wrapping */
}

.tooltip-container:hover .tooltip-text-container {
    display: block;
}

.tooltip-text-container::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -5px; /* Adjust to place the arrow appropriately */
    transform: translateY(-50%) rotate(45deg);
    width: 8px; /* Adjust arrow size */
    height: 8px; /* Adjust arrow size */
    background-color: #000; /* Match background color */
}
